import React, {useCallback, useEffect, useState} from "react";
import {ProgressBar} from "react-bootstrap";
import {useLocation} from "react-router-dom";

export default function AnimateLoading() {
  const location = useLocation();
  const [animateTimeout, setAnimateTimeout] = useState();
  const [stopAnimateTimeout, setStopAnimateTimeout] = useState();
  const [state, setState] = useState({
    width: 0,
  });
  const scrollToTop = useCallback(() => {
    const scrollToTopBtn = document.getElementById("kt_scrolltop");
    if (scrollToTopBtn) {
      scrollToTopBtn.click();
    }
  }, []);
  const stopAnimate = useCallback((_animateTimeout) => {
    clearTimeout(_animateTimeout);
    setStopAnimateTimeout(() => setTimeout(() => {
      setState({width: 0})
    }, 300));
  }, []);
  
  const animate = useCallback((_state) => {
    const _animateTimeout = setTimeout(() => {
      const width = _state?.width ?? state.width;
      if (width <= 100) {
        setState({width: width + 10});
        animate({width: width + 10});
      } else {
        stopAnimate(_animateTimeout);
      }
    }, 30);
    setAnimateTimeout(_animateTimeout);
  }, [stopAnimate]);
  
  // useEffect(() => {
  //   animate();
  //   scrollToTop();
  // }, [location.pathname]);
  //
  useEffect(() => {
    animate(state);
    scrollToTop();
    return () => {
      if (stopAnimateTimeout) {
        clearTimeout(stopAnimateTimeout);
      }
      if (animateTimeout) {
        clearTimeout(animateTimeout);
      }
    }
  }, [location.pathname])
  
  return (
    <div
      className="header-progress-bar"
      style={{height: "3px", width: "100%"}}
    >
      {state.width > 0 && (
        <ProgressBar variant="info" now={state.width} style={{height: "3px"}}/>
      )}
    </div>
  );
}
