import { Col, Collapse, Descriptions, Layout } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
const { Panel } = Collapse;
const { Header, Content } = Layout;

export default function Txng() {
  const { id = '' } = useParams<{ id: string }>();
  const [data, setData] = useState<any>();

  const fetchData = async () => {
    try {
      const _res = await axios.get(`https://api.vncheck.net/api/qrcode/${id}/public`);
      const res = await axios.post(`https://api.vncheck.net/api/mobile/qrcode/${id}/public`, {
        location: _res?.data?.lastGPS?.length ? _res?.data?.lastGPS : [21.027763, 105.83416],
      });
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout>
      <Header
        style={{
          backgroundColor: '#28AE60',
          height: '100px',
          paddingInline: 24,
        }}>
        <div className="">
          <img src="/media/logos/vncheck-company-logo.svg" alt="logo" />
        </div>
      </Header>
      <Content>
        <div
          className="site-layout-background"
          style={{ paddingInline: 24, paddingBlock: 24, minHeight: 380 }}>
          <Collapse>
            {data?.retailInfo && (
              <Panel header="Trạng thái" key="1">
                <Col>
                  <Descriptions layout="vertical" contentStyle={{ fontWeight: 'bold' }}>
                    <Descriptions.Item label="Trạng thái">
                      {data?.retailInfo?.isSold ? 'Đã bán' : 'Chưa bán'}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions layout="vertical" contentStyle={{ fontWeight: 'bold' }}>
                    <Descriptions.Item label="Thời gian bán">
                      {data?.retailInfo?.soldDate ?? '--'}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions layout="vertical" contentStyle={{ fontWeight: 'bold' }}>
                    <Descriptions.Item label="Nơi bán">
                      {data?.retailInfo?.soldAt?.name ?? data?.lastGPS?.join?.(', ') ?? '--'}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions layout="vertical" contentStyle={{ fontWeight: 'bold' }}>
                    <Descriptions.Item label="Nhân viên nơi bán">
                      {data?.retailInfo?.soldBy?.fullName ?? '--'}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions layout="vertical" contentStyle={{ fontWeight: 'bold' }}>
                    <Descriptions.Item label="ID QR">{id}</Descriptions.Item>
                  </Descriptions>
                </Col>
              </Panel>
            )}
            {data?.enterprise && (
              <Panel header="Doanh nghiệp sản xuất" key="2">
                <Descriptions layout="vertical" contentStyle={{ fontWeight: 'bold' }}>
                  <Descriptions.Item label="Tên doanh nghiệp">
                    {data?.enterprise?.name ?? '--'}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions layout="vertical" contentStyle={{ fontWeight: 'bold' }}>
                  <Descriptions.Item label="Mã số thuế">
                    {data?.enterprise?.taxId ?? '--'}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions layout="vertical" contentStyle={{ fontWeight: 'bold' }}>
                  <Descriptions.Item label="Địa chỉ">
                    {data?.enterprise?.address ?? '--'}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions layout="vertical" contentStyle={{ fontWeight: 'bold' }}>
                  <Descriptions.Item label="Số điện thoại">
                    {data?.enterprise?.phone}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions layout="vertical" contentStyle={{ fontWeight: 'bold' }}>
                  <Descriptions.Item label="Người đại diện">
                    {data?.enterprise?.presentedBy}
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            )}
            {data?.species && (
              <Panel header="Thông tin chung" key="3">
                <Descriptions layout="vertical" contentStyle={{ fontWeight: 'bold' }}>
                  <Descriptions.Item label="Tên chủng loại">
                    {data?.species?.name ?? '--'}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions layout="vertical" contentStyle={{ fontWeight: 'bold' }}>
                  <Descriptions.Item label="Đăng ký vạch">
                    {data?.species?.barcode ?? '--'}
                  </Descriptions.Item>
                </Descriptions>
                {data?.species?.website && (
                  <Descriptions layout="vertical" contentStyle={{ fontWeight: 'bold' }}>
                    <Descriptions.Item label="Liên kết">
                      <a href={data?.species?.website} target="_blank">
                        Xem chi tiết
                      </a>
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </Panel>
            )}
          </Collapse>

          <div style={{ paddingTop: 24, textAlign: 'center' }}>
            <h4 className="text-center mb-4">
              Để xem nhiều thông tin hơn, hãy sử dụng ứng dụng truy xuất nguồn gốc của chúng tôi
            </h4>
            <div>
              <a
                href="https://apps.apple.com/us/app/vn-check/id6480041252"
                style={{ paddingInline: 16 }}>
                <img
                  src="https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg"
                  alt="Download on the App Store"
                  style={{ width: 224 }}
                />
              </a>
            </div>
            <div>
              <a href="https://play.google.com/store/apps/dev?id=7452729348131294368">
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  style={{ width: 256 }}
                />
              </a>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
}
