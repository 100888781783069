import React from 'react';
import { RootStateOrAny, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Card } from '../../common-library/card';
import {
  RenderInfoDetail,
  RenderInfoDetailColumn,
} from '../../common-library/common-types/common-type';
import { ToAbsoluteUrl } from '../../common-library/helpers/assets-helpers';
import { AuthPage } from '../auth';
import { notifyError } from '../production-plan/defined/crud-helped';
import { ProductionPlanDetail } from '../production-plan/production-plan-detail';
import { entityExample, exampleDetail } from './example-data';
import { FTDetail } from './food-traceability-detail';
import { GetDetail, GetDetailWithAuth } from './food-traceability.service';
import { GetById } from './food-traceability.service';
import './style.scss';

const getExistInfo = (target: string[], current: RenderInfoDetailColumn) => {
  const viewData: RenderInfoDetailColumn = {};

  Object.keys(current).forEach(role => {
    if (target.includes(role)) {
      viewData[role] = current[role];
    }
  });

  return viewData;
};

function FoodTraceability({ id }: { id?: string }) {
  const [entity, setEntity] = React.useState<any>();
  const [viewDetail, setViewDetail] = React.useState<RenderInfoDetail>(exampleDetail);
  const { auth } = useSelector((state: RootStateOrAny) => ({ auth: state.auth }), shallowEqual);

  React.useEffect(() => {
    // const vData = [exampleDetail[0], exampleDetail[1], exampleDetail[2]]
    // if (!auth?._id) {
    //   setRenderInfo(vData)
    // }

    if (id) {
      if (auth?._id) {
        GetDetailWithAuth(id)
          .then(res => {
            setEntity(res.data);
          })
          .catch(err => notifyError('QR_NOT_FOUND'));
      } else {
        GetDetail(id)
          .then(res => {
            setEntity(res.data);
          })
          .catch(err => notifyError('QR_NOT_FOUND'));
      }
    }

    // if (auth?._id) {
    //   GetById(auth._id).then(res => {
    //     const userRole: string[] = res.data?.addedScope?.enable ?? []
    //     const viewInfo: RenderInfoDetail = exampleDetail.map(item => {
    //       return ({
    //       ...item,
    //       data: getExistInfo(userRole, item.data)
    //     })})

    //     console.log(viewInfo)
    //     setViewDetail(viewInfo)
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // } else {
    //   const viewInfo: RenderInfoDetail = exampleDetail.map(item => {
    //     return ({
    //     ...item,
    //     data: getExistInfo([], item.data)
    //   })})

    //   console.log(viewInfo)
    //   setViewDetail(viewInfo)
    // }
  }, [auth, id]);

  return (
    <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
      <Card>
      <div className="text-center">
        <div className="d-md-flex justify-content-center align-items-center bg-primary p-5">
          <div>
          <img src={ToAbsoluteUrl('/media/logos/vncheck-company-logo.svg')} alt="logo" width="100" />
          </div>
          <h1 className="text-center text-white font-weight-bolder">VNCHECK - Hệ thống truy xuất nguồn gốc thực phẩm số 1 Việt Nam</h1>
        </div>
        {/* {auth?._id ? 'Đã đăng nhập' : 'Chưa đăng nhập'}
        <h4>{!auth?._id && 'Vui lòng đăng nhập để xem nhiều thông tin hơn'}</h4>
        {auth?.username && <h6>Tên đăng nhập: {auth?.username}</h6>}
        {auth?.role && <h6>Vai trò: {auth?.role?.name}</h6>} */}
      </div>
      <div className="w-md-75 w-lg-50 mx-auto mt-5">
        <FTDetail entity={entity} renderInfo={exampleDetail} showComment={false} />
      </div>
      <h4 className="text-center font-weight-bolder mt-15">
        Để xem nhiều thông tin hơn, hãy sử dụng App truy xuất nguồn gốc của chúng tôi
      </h4>
      <div className="d-md-flex justify-content-center text-center pb-10 mt-5">
        <div className="mx-md-2 mt-3">
          <a href="https://play.google.com/store/apps/details?id=com.sotatek.vncheckfarm" target="_blank" rel="noreferrer">
          <img
            src={ToAbsoluteUrl('/media/client-logos/googleplay.jpg')}
            className="download-image"
            alt=""
          />
          </a>
         
        </div>
        <div className="mx-md-2 mt-3">
          <img
            src={ToAbsoluteUrl('/media/client-logos/appstore.png')}
            className="download-image"
            alt=""
          />
        </div>
      </div>
      </Card>
    </div>
  );
}

export default FoodTraceability;
